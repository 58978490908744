   input[type=number] {
    height: 45px;
    width: 45px;
    font-size: 25px;
    text-align: center;
    border: 1px solid #FFFF;
    background: #FFFFFF;

    box-shadow: 0px 5px 25px rgba(42, 122, 227, 0.06);
    border-radius: 15px;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }