 /*Add here all your css styles (customizations) */
  /* body {
   background-color: #F8FBFF;
 } */

 /*font-----------------------------------*/
 body,
 div,
 p {
   font-family: 'DM Sans', sans-serif !important;
 }

 table,
 tr,
 td,
 th {
   font-family: 'DM Sans', sans-serif !important;
 }

 h1,
 h2,
 h3 {
   font-family: 'DM Sans', sans-serif !important;
 }

 ul li {
   font-family: 'DM Sans', sans-serif !important;
 }

 a {
   font-family: 'DM Sans', sans-serif !important;

 }

 input,
 select,
 label,
 span {
   font-family: 'DM Sans', sans-serif !important;
 }

 .media-body {
   font-family: 'DM Sans', sans-serif !important;
   /*font-family: 'Roboto', sans-serif !important;*/
   /*font-family: 'Kanit', sans-serif !important;*/
 }

 .g-pointer {
   cursor: pointer
 }

 .g-color-lang {
   background-color: #F8F8FF;
   color: white;
 }

 /*MENU*/

 .menu-name {
   font-size: 8px;
   line-height: 10.96px;
   font-weight: 500px;
   padding-top: 2px;
   color: white;
 }

 .filter-green {
   filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
 }

 .svg-reverse {
   /*-webkit-filter: invert(.75); /* safari 6.0 - 9.0 */
   /*filter: invert(.75);*/

   /*-webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
   /*filter: invert(100%);*/

   /*transform: scaleX(-1);*/
   /*transform-origin: center;*/
   /*transform-box: fill-box;*/


 }

 /*popular slide*/
 .aspect-16to9 {
   height: auto;
   width: 100%;
 }

 .p-16to9 {
   position: relative;
   padding-bottom: 56%;
   overflow: hidden;
 }

 .card-body-content {
   position: absolute;
   top: 1rem;
   bottom: 1rem;
   right: 1rem;
   left: 1rem;
   overflow: hidden;
 }

 /*popular slide*/

 /*MENU*/

 .btn-linelogin {
   border-radius: 20px;
   background-color: #06C755;
   color: while;
 }


 .bg-onesmile {

   background: linear-gradient(108.73deg, #F9881F 23.73%, #F9881F 23.73%, #FF774C 79.34%);
   color: white;
 }

 .bg-onesmile-orange {
   background-color: #FE554a;
 }

 .g-color-onsmile {
   color: #FE554A;
 }

 .g-color-black-onesmile {
   color: #3D3D3D;
 }

 .btn-onesmile {
   background: linear-gradient(108.73deg, #F9881F 23.73%, #F9881F 23.73%, #FF774C 79.34%);
   border-radius: 20px;
   color: white;
 }

 .btn-onesmile-shadow {
   background: linear-gradient(108.73deg, #F9881F 23.73%, #F9881F 23.73%, #FF774C 79.34%);
   border-radius: 20px;
   color: white;
   filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
 }

 /*h2 {
  font-family: 'Roboto', sans-serif !important;
  font-family: 'Kanit', sans-serif !important;
  }*/

 /*font-----------------------------------*/


 /*blink---- ข้อความกระพริบ----------------------------------*/
 .blink {
   animation: blink-animation 1s steps(5, start) infinite;
   -webkit-animation: blink-animation 1s steps(5, start) infinite;
 }

 @keyframes blink-animation {
   to {
     visibility: hidden;
   }
 }

 @-webkit-keyframes blink-animation {
   to {
     visibility: hidden;
   }
 }

 /*blink---- ข้อความกระพริบ----------------------------------*/

 /*
::selection {
  color: white;
  background: blue;
}
*/




 /*เทา #8D9090*/
 /*ดำ #323333*/
 .g-bg-mtg {
   background-color: #777777 !important;
 }

 .g-color-mtg {
   color: #777777 !important;
 }

 .u-btn-mtg {
   color: #fff;
   background-color: #777777;
 }

 .g-bg-mtg-sale {
   background-color: #DE413A !important;
 }

 .g-color-mtg-sale {
   color: #DE413A !important;
 }

 .u-btn-mtg-sale {
   color: #fff;
   background-color: #DE413A;
 }

 .g-bg-mtg-newa {
   background-color: #8D9090 !important;
 }

 .g-color-mtg-newa {
   color: #8D9090 !important;
 }

 .u-btn-mtg-newa {
   color: #fff;
   background-color: #8D9090;
 }

 .g-bg-mtg-soldout {
   background-color: #BEC0C0 !important;
 }

 .g-color-mtg-soldout {
   color: #BEC0C0 !important;
 }

 .u-btn-mtg-soldout {
   color: #fff;
   background-color: #BEC0C0;
 }

 .g-bg-mtg-red {
   background-color: #DE413A !important;
 }

 .g-color-mtg-red {
   color: #DE413A !important;
 }

 .u-btn-mtg-red {
   color: #fff;
   background-color: #DE413A;
 }

 .href-language {
   cursor: pointer;
 }

 /*loading*/
 #loading {
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   position: fixed;
   display: block;
   opacity: 0.7;
   background-color: #fff;
   z-index: 99;
   text-align: center;
 }

 #loading-image {
   position: absolute;
   top: 100px;
   left: 240px;
   z-index: 100;
 }

 /*loading*/

 .hide {
   display: none;
 }

 .g-overflow-hidden {
   width: 100%;
   height: 100%;
   background-size: cover;
   background-position: 50%;
   background-repeat: no-repeat;
   background: #C8C8C8;
 }

 .g-text-hover-pointer {
   cursor: pointer;
 }


 .blink {
   animation: blink-animation 1s steps(5, start) infinite;
   -webkit-animation: blink-animation 1s steps(5, start) infinite;
 }

 @keyframes blink-animation {
   to {
     visibility: hidden;
   }
 }

 @-webkit-keyframes blink-animation {
   to {
     visibility: hidden;
   }
 }



 /*!
 * Bootoast CSS
 * @author odahcam
 * @version 1.0.0
 **/

 .bootoast {
   opacity: 0;
   filter: alpha(opacity=0)
 }

 .bootoast>.glyphicon {
   display: inline-block;
   float: left;
   font-size: 20px;
   margin-right: 14px;
 }

 .bootoast>.bootoast-alert-container {
   display: block;
   max-width: 100%;
   overflow: auto;
 }

 .bootoast>.bootoast-alert-container>.bootoast-alert-content {
   display: table;
   width: 100%;
   height: 100%
 }

 .bootoast-container {
   position: fixed;
   left: 20px;
   right: 20px;
   top: initial;
   bottom: initial;
   width: 390px;
   max-width: 95%;
   z-index: 2060
 }

 .bootoast-container.top {
   top: 20px
 }

 .bootoast-container.bottom {
   bottom: 20px
 }

 .bootoast-container.right {
   left: initial
 }

 .bootoast-container.left {
   right: initial
 }

 .bootoast-container.center {
   left: 50%;
   -webkit-transform: translateX(-50%);
   -moz-transform: translateX(-50%);
   -ms-transform: translateX(-50%);
   -o-transform: translateX(-50%);
   transform: translateX(-50%)
 }


 /*box-ribbon*/
 .box-ribbon {
   position: relative;
   max-width: 600px;
   width: 90%;
   height: 400px;
   background: #fff;
   box-shadow: 0 0 15px rgba(0, 0, 0, .1);
 }

 .ribbon {
   width: 150px;
   height: 150px;
   overflow: hidden;
   position: absolute;
 }

 .ribbon::before,
 .ribbon::after {
   position: absolute;
   z-index: -1;
   content: '';
   display: block;
   border: 5px solid #2980b9;
 }

 .ribbon span {
   position: absolute;
   display: block;
   width: 225px;
   padding: 5px 0;
   background-color: #3498db;
   box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
   color: #fff;
   font: 500 12px/1 'Lato', sans-serif;
   text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
   text-transform: uppercase;
   text-align: center;
 }




 /* top left*/
 .ribbon-top-left {
   top: -10px;
   left: -10px;
 }

 .ribbon-top-left::before,
 .ribbon-top-left::after {
   border-top-color: transparent;
   border-left-color: transparent;
 }

 .ribbon-top-left::before {
   top: 0;
   right: 0;
 }

 .ribbon-top-left::after {
   bottom: 0;
   left: 0;
 }

 .ribbon-top-left span {
   right: -25px;
   top: 10px;
   transform: rotate(-45deg);
 }

 /* top right*/
 .ribbon-top-right {
   top: -10px;
   right: -10px;
 }

 .ribbon-top-right::before,
 .ribbon-top-right::after {
   border-top-color: transparent;
   border-right-color: transparent;
 }

 .ribbon-top-right::before {
   top: 0;
   left: 0;
 }

 .ribbon-top-right::after {
   bottom: 0;
   right: 0;
 }

 .ribbon-top-right span {
   left: -25px;
   top: 30px;
   transform: rotate(45deg);
 }

 /* bottom left*/
 .ribbon-bottom-left {
   bottom: -10px;
   left: -10px;
 }

 .ribbon-bottom-left::before,
 .ribbon-bottom-left::after {
   border-bottom-color: transparent;
   border-left-color: transparent;
 }

 .ribbon-bottom-left::before {
   bottom: 0;
   right: 0;
 }

 .ribbon-bottom-left::after {
   top: 0;
   left: 0;
 }

 .ribbon-bottom-left span {
   right: -25px;
   bottom: 30px;
   transform: rotate(225deg);
 }

 /* bottom right*/
 .ribbon-bottom-right {
   bottom: -10px;
   right: -10px;
 }

 .ribbon-bottom-right::before,
 .ribbon-bottom-right::after {
   border-bottom-color: transparent;
   border-right-color: transparent;
 }

 .ribbon-bottom-right::before {
   bottom: 0;
   left: 0;
 }

 .ribbon-bottom-right::after {
   top: 0;
   right: 0;
 }

 .ribbon-bottom-right span {
   left: -25px;
   bottom: 30px;
   transform: rotate(-225deg);
 }



 /*///////////// loading*/


 #overlay {
   position: fixed;
   z-index: 10000;
   top: 0;
   /*z-index: 100;*/
   width: 100%;
   height: 100%;
   display: none;
   background: rgba(0, 0, 0, 0.6);
 }

 .cv-spinner {
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .spinner {
   width: 40px;
   height: 40px;
   border: 4px #ddd solid;
   border-top: 4px #2e93e6 solid;
   border-radius: 50%;
   animation: sp-anime 0.8s infinite linear;
 }

 @keyframes sp-anime {
   100% {
     transform: rotate(360deg);
   }
 }

 .is-hide {
   display: none;
 }

 