.form-control-feedback {
    padding-left: 10px;
    color: #dc3545;
    font-weight: 600;
    font-size: 12px;
    /* color: white; */
    /* background-color: red; */
    /* width: 100%; */
}


input[type='date'],
input[type='time'] {
    -webkit-appearance: none;
}
 select {
    -webkit-appearance:none;
}