 	.loader {
 		color: #fff;
 		position: fixed;
 		box-sizing: border-box;
 		left: -9999px;
 		top: -9999px;
 		width: 0;
 		height: 0;
 		overflow: hidden;
 		z-index: 999999
 	}

 	.loader:after,
 	.loader:before {
 		box-sizing: border-box;
 		display: none
 	}

 	.loader.is-active {
 		background-color: rgba(0, 0, 0, .85);
 		width: 100%;
 		height: 100%;
 		left: 0;
 		top: 0
 	}

 	.loader.is-active:after,
 	.loader.is-active:before {
 		display: block
 	}

 	@keyframes rotation {
 		0% {
 			transform: rotate(0)
 		}
 		to {
 			transform: rotate(359deg)
 		}
 	}

 	@keyframes blink {
 		0% {
 			opacity: .5
 		}
 		to {
 			opacity: 1
 		}
 	}

 	.loader[data-text]:before {
 		position: fixed;
 		left: 0;
 		top: 50%;
 		color: currentColor;
 		font-family: Helvetica, Arial, sans-serif;
 		text-align: center;
 		width: 100%;
 		font-size: 14px
 	}

 	.loader[data-text=""]:before {
 		content: "Loading"
 	}

 	.loader[data-text]:not([data-text=""]):before {
 		content: attr(data-text)
 	}

 	.loader[data-text][data-blink]:before {
 		animation: blink 1s linear infinite alternate
 	}

 	.loader-default[data-text]:before {
 		top: calc(50% - 63px)
 	}

 	.loader-default:after {
 		content: "";
 		position: fixed;
 		width: 48px;
 		height: 48px;
 		border: 8px solid #fff;
 		border-left-color: transparent;
 		border-radius: 50%;
 		top: calc(50% - 24px);
 		left: calc(50% - 24px);
 		animation: rotation 1s linear infinite
 	}

 	.loader-default[data-half]:after {
 		border-right-color: transparent
 	}

 	.loader-default[data-inverse]:after {
 		animation-direction: reverse
 	}

 	.loader-double:after,
 	.loader-double:before {
 		content: "";
 		position: fixed;
 		border-radius: 50%;
 		border: 8px solid;
 		animation: rotation 1s linear infinite
 	}

 	.loader-double:after {
 		width: 48px;
 		height: 48px;
 		border-color: #fff;
 		border-left-color: transparent;
 		top: calc(50% - 24px);
 		left: calc(50% - 24px)
 	}

 	.loader-double:before {
 		width: 64px;
 		height: 64px;
 		border-color: #eb974e;
 		border-right-color: transparent;
 		animation-duration: 2s;
 		top: calc(50% - 32px);
 		left: calc(50% - 32px)
 	}

 	.loader-bar[data-text]:before {
 		top: calc(50% - 40px);
 		color: #fff
 	}

 	.loader-bar:after {
 		content: "";
 		position: fixed;
 		top: 50%;
 		left: 50%;
 		width: 200px;
 		height: 20px;
 		transform: translate(-50%, -50%);
 		background: linear-gradient(-45deg, #4183d7 25%, #52b3d9 0, #52b3d9 50%, #4183d7 0, #4183d7 75%, #52b3d9 0, #52b3d9);
 		background-size: 20px 20px;
 		box-shadow: inset 0 10px 0 hsla(0, 0%, 100%, .2), 0 0 0 5px rgba(0, 0, 0, .2);
 		animation: moveBar 1.5s linear infinite reverse
 	}

 	.loader-bar[data-rounded]:after {
 		border-radius: 15px
 	}

 	.loader-bar[data-inverse]:after {
 		animation-direction: normal
 	}

 	@keyframes moveBar {
 		0% {
 			background-position: 0 0
 		}
 		to {
 			background-position: 20px 20px
 		}
 	}

 	.loader-bar-ping-pong:before {
 		width: 200px;
 		background-color: #000
 	}

 	.loader-bar-ping-pong:after,
 	.loader-bar-ping-pong:before {
 		content: "";
 		height: 20px;
 		position: absolute;
 		top: calc(50% - 10px);
 		left: calc(50% - 100px)
 	}

 	.loader-bar-ping-pong:after {
 		width: 50px;
 		background-color: #f19;
 		animation: moveBarPingPong .5s linear infinite alternate
 	}

 	.loader-bar-ping-pong[data-rounded]:before {
 		border-radius: 10px
 	}

 	.loader-bar-ping-pong[data-rounded]:after {
 		border-radius: 50%;
 		width: 20px;
 		animation-name: moveBarPingPongRounded
 	}

 	@keyframes moveBarPingPong {
 		0% {
 			left: calc(50% - 100px)
 		}
 		to {
 			left: calc(50% - -50px)
 		}
 	}

 	@keyframes moveBarPingPongRounded {
 		0% {
 			left: calc(50% - 100px)
 		}
 		to {
 			left: calc(50% - -80px)
 		}
 	}

 	@keyframes corners {
 		6% {
 			width: 60px;
 			height: 15px
 		}
 		25% {
 			width: 15px;
 			height: 15px;
 			left: calc(100% - 15px);
 			top: 0
 		}
 		31% {
 			height: 60px
 		}
 		50% {
 			height: 15px;
 			top: calc(100% - 15px);
 			left: calc(100% - 15px)
 		}
 		56% {
 			width: 60px
 		}
 		75% {
 			width: 15px;
 			left: 0;
 			top: calc(100% - 15px)
 		}
 		81% {
 			height: 60px
 		}
 	}

 	.loader-border[data-text]:before {
 		color: #fff
 	}

 	.loader-border:after {
 		content: "";
 		position: absolute;
 		top: 0;
 		left: 0;
 		width: 15px;
 		height: 15px;
 		background-color: #ff0;
 		animation: corners 3s ease both infinite
 	}

 	.loader-ball:before {
 		content: "";
 		position: absolute;
 		width: 50px;
 		height: 50px;
 		top: 50%;
 		left: 50%;
 		margin: -25px 0 0 -25px;
 		background-color: #fff;
 		border-radius: 50%;
 		z-index: 1;
 		animation: kickBall 1s infinite alternate ease-in both
 	}

 	.loader-ball[data-shadow]:before {
 		box-shadow: inset -5px -5px 10px 0 rgba(0, 0, 0, .5)
 	}

 	.loader-ball:after {
 		content: "";
 		position: absolute;
 		background-color: rgba(0, 0, 0, .3);
 		border-radius: 50%;
 		width: 45px;
 		height: 20px;
 		top: calc(50% + 10px);
 		left: 50%;
 		margin: 0 0 0 -22.5px;
 		z-index: 0;
 		animation: shadow 1s infinite alternate ease-out both
 	}

 	@keyframes shadow {
 		0% {
 			background-color: transparent;
 			transform: scale(0)
 		}
 		40% {
 			background-color: transparent;
 			transform: scale(0)
 		}
 		95% {
 			background-color: rgba(0, 0, 0, .75);
 			transform: scale(1)
 		}
 		to {
 			background-color: rgba(0, 0, 0, .75);
 			transform: scale(1)
 		}
 	}

 	@keyframes kickBall {
 		0% {
 			transform: translateY(-80px) scaleX(.95)
 		}
 		90% {
 			border-radius: 50%
 		}
 		to {
 			transform: translateY(0) scaleX(1);
 			border-radius: 50% 50% 20% 20%
 		}
 	}

 	.loader-smartphone:after {
 		content: "";
 		color: #fff;
 		font-size: 12px;
 		font-family: Helvetica, Arial, sans-serif;
 		text-align: center;
 		line-height: 120px;
 		position: fixed;
 		left: 50%;
 		top: 50%;
 		width: 70px;
 		height: 130px;
 		margin: -65px 0 0 -35px;
 		border: 5px solid #fd0;
 		border-radius: 10px;
 		box-shadow: inset 0 5px 0 0 #fd0;
 		background: radial-gradient(circle at 50% 90%, rgba(0, 0, 0, .5) 6px, transparent 0), linear-gradient(0deg, #fd0 22px, transparent 0), linear-gradient(0deg, rgba(0, 0, 0, .5) 22px, rgba(0, 0, 0, .5));
 		animation: shake 2s cubic-bezier(.36, .07, .19, .97) both infinite
 	}

 	.loader-smartphone[data-screen=""]:after {
 		content: "Loading"
 	}

 	.loader-smartphone:not([data-screen=""]):after {
 		content: attr(data-screen)
 	}

 	@keyframes shake {
 		5% {
 			transform: translate3d(-1px, 0, 0)
 		}
 		10% {
 			transform: translate3d(1px, 0, 0)
 		}
 		15% {
 			transform: translate3d(-1px, 0, 0)
 		}
 		20% {
 			transform: translate3d(1px, 0, 0)
 		}
 		25% {
 			transform: translate3d(-1px, 0, 0)
 		}
 		30% {
 			transform: translate3d(1px, 0, 0)
 		}
 		35% {
 			transform: translate3d(-1px, 0, 0)
 		}
 		40% {
 			transform: translate3d(1px, 0, 0)
 		}
 		45% {
 			transform: translate3d(-1px, 0, 0)
 		}
 		50% {
 			transform: translate3d(1px, 0, 0)
 		}
 		55% {
 			transform: translate3d(-1px, 0, 0)
 		}
 	}

 	.loader-clock:before {
 		width: 120px;
 		height: 120px;
 		border-radius: 50%;
 		margin: -60px 0 0 -60px;
 		background: linear-gradient(180deg, transparent 50%, #f5f5f5 0), linear-gradient(90deg, transparent 55px, #2ecc71 0, #2ecc71 65px, transparent 0), linear-gradient(180deg, #f5f5f5 50%, #f5f5f5 0);
 		box-shadow: inset 0 0 0 10px #f5f5f5, 0 0 0 5px #555, 0 0 0 10px #7b7b7b;
 		animation: rotation infinite 2s linear
 	}

 	.loader-clock:after,
 	.loader-clock:before {
 		content: "";
 		position: fixed;
 		left: 50%;
 		top: 50%;
 		overflow: hidden
 	}

 	.loader-clock:after {
 		width: 60px;
 		height: 40px;
 		margin: -20px 0 0 -15px;
 		border-radius: 20px 0 0 20px;
 		background: radial-gradient(circle at 14px 20px, #25a25a 10px, transparent 0), radial-gradient(circle at 14px 20px, #1b7943 14px, transparent 0), linear-gradient(180deg, transparent 15px, #2ecc71 0, #2ecc71 25px, transparent 0);
 		animation: rotation infinite 24s linear;
 		transform-origin: 15px center
 	}

 	.loader-curtain:after,
 	.loader-curtain:before {
 		position: fixed;
 		width: 100%;
 		top: 50%;
 		margin-top: -35px;
 		font-size: 70px;
 		text-align: center;
 		font-family: Helvetica, Arial, sans-serif;
 		overflow: hidden;
 		line-height: 1.2;
 		content: "Loading"
 	}

 	.loader-curtain:before {
 		color: #666
 	}

 	.loader-curtain:after {
 		color: #fff;
 		height: 0;
 		animation: curtain 1s linear infinite alternate both
 	}

 	.loader-curtain[data-curtain-text]:not([data-curtain-text=""]):after,
 	.loader-curtain[data-curtain-text]:not([data-curtain-text=""]):before {
 		content: attr(data-curtain-text)
 	}

 	.loader-curtain[data-brazilian]:before {
 		color: #f1c40f
 	}

 	.loader-curtain[data-brazilian]:after {
 		color: #2ecc71
 	}

 	.loader-curtain[data-colorful]:before {
 		animation: maskColorful 2s linear infinite alternate both
 	}

 	.loader-curtain[data-colorful]:after {
 		animation: curtain 1s linear infinite alternate both, maskColorful-front 2s 1s linear infinite alternate both;
 		color: #000
 	}

 	@keyframes maskColorful {
 		0% {
 			color: #3498db
 		}
 		49.5% {
 			color: #3498db
 		}
 		50.5% {
 			color: #e74c3c
 		}
 		to {
 			color: #e74c3c
 		}
 	}

 	@keyframes maskColorful-front {
 		0% {
 			color: #2ecc71
 		}
 		49.5% {
 			color: #2ecc71
 		}
 		50.5% {
 			color: #f1c40f
 		}
 		to {
 			color: #f1c40f
 		}
 	}

 	@keyframes curtain {
 		0% {
 			height: 0
 		}
 		to {
 			height: 84px
 		}
 	}

 	.loader-music:after,
 	.loader-music:before {
 		content: "";
 		position: fixed;
 		width: 240px;
 		height: 240px;
 		top: 50%;
 		left: 50%;
 		margin: -120px 0 0 -120px;
 		border-radius: 50%;
 		text-align: center;
 		line-height: 240px;
 		color: #fff;
 		font-size: 40px;
 		font-family: Helvetica, Arial, sans-serif;
 		text-shadow: 1px 1px 0 rgba(0, 0, 0, .5);
 		letter-spacing: -1px
 	}

 	.loader-music:after {
 		backface-visibility: hidden
 	}

 	.loader-music[data-hey-oh]:after,
 	.loader-music[data-hey-oh]:before {
 		box-shadow: 0 0 0 10px
 	}

 	.loader-music[data-hey-oh]:before {
 		background-color: #fff;
 		color: #000;
 		animation: coinBack 2.5s linear infinite, oh 5s 1.25s linear infinite both
 	}

 	.loader-music[data-hey-oh]:after {
 		background-color: #000;
 		animation: coin 2.5s linear infinite, hey 5s linear infinite both
 	}

 	.loader-music[data-no-cry]:after,
 	.loader-music[data-no-cry]:before {
 		background: linear-gradient(45deg, #009b3a 50%, #fed100 51%);
 		box-shadow: 0 0 0 10px #000
 	}

 	.loader-music[data-no-cry]:before {
 		animation: coinBack 2.5s linear infinite, cry 5s 1.25s linear infinite both
 	}

 	.loader-music[data-no-cry]:after {
 		animation: coin 2.5s linear infinite, no 5s linear infinite both
 	}

 	.loader-music[data-we-are]:before {
 		animation: coinBack 2.5s linear infinite, theWorld 5s 1.25s linear infinite both;
 		background: radial-gradient(ellipse at center, #4ecdc4 0, #556270)
 	}

 	.loader-music[data-we-are]:after {
 		animation: coin 2.5s linear infinite, weAre 5s linear infinite both;
 		background: radial-gradient(ellipse at center, #26d0ce 0, #1a2980)
 	}

 	.loader-music[data-rock-you]:before {
 		animation: coinBack 2.5s linear infinite, rockYou 5s 1.25s linear infinite both;
 		background: #444
 	}

 	.loader-music[data-rock-you]:after {
 		animation: coin 2.5s linear infinite, weWill 5s linear infinite both;
 		background: #96281b
 	}

 	@keyframes coin {
 		to {
 			transform: rotateY(359deg)
 		}
 	}

 	@keyframes coinBack {
 		0% {
 			transform: rotateY(180deg)
 		}
 		50% {
 			transform: rotateY(1turn)
 		}
 		to {
 			transform: rotateY(180deg)
 		}
 	}

 	@keyframes hey {
 		0% {
 			content: "Hey!"
 		}
 		50% {
 			content: "Let's!"
 		}
 		to {
 			content: "Hey!"
 		}
 	}

 	@keyframes oh {
 		0% {
 			content: "Oh!"
 		}
 		50% {
 			content: "Go!"
 		}
 		to {
 			content: "Oh!"
 		}
 	}

 	@keyframes no {
 		0% {
 			content: "No..."
 		}
 		50% {
 			content: "no"
 		}
 		to {
 			content: "No..."
 		}
 	}

 	@keyframes cry {
 		0% {
 			content: "woman"
 		}
 		50% {
 			content: "cry!"
 		}
 		to {
 			content: "woman"
 		}
 	}

 	@keyframes weAre {
 		0% {
 			content: "We are"
 		}
 		50% {
 			content: "we are"
 		}
 		to {
 			content: "We are"
 		}
 	}

 	@keyframes theWorld {
 		0% {
 			content: "the world,"
 		}
 		50% {
 			content: "the children!"
 		}
 		to {
 			content: "the world,"
 		}
 	}

 	@keyframes weWill {
 		0% {
 			content: "We will,"
 		}
 		50% {
 			content: "rock you!"
 		}
 		to {
 			content: "We will,"
 		}
 	}

 	@keyframes rockYou {
 		0% {
 			content: "we will"
 		}
 		50% {
 			content: "\1F918"
 		}
 		to {
 			content: "we will"
 		}
 	}

 	.loader-pokeball:before {
 		content: "";
 		position: absolute;
 		width: 100px;
 		height: 100px;
 		top: 50%;
 		left: 50%;
 		margin: -50px 0 0 -50px;
 		background: linear-gradient(180deg, red 42%, #000 0, #000 58%, #fff 0);
 		background-repeat: no-repeat;
 		background-color: #fff;
 		border-radius: 50%;
 		z-index: 1;
 		animation: movePokeball 1s linear infinite both
 	}

 	.loader-pokeball:after {
 		content: "";
 		position: absolute;
 		width: 24px;
 		height: 24px;
 		top: 50%;
 		left: 50%;
 		margin: -12px 0 0 -12px;
 		background-color: #fff;
 		border-radius: 50%;
 		z-index: 2;
 		animation: movePokeball 1s linear infinite both, flashPokeball .5s infinite alternate;
 		border: 2px solid #000;
 		box-shadow: 0 0 0 5px #fff, 0 0 0 10px #000
 	}

 	@keyframes movePokeball {
 		0% {
 			transform: translateX(0) rotate(0)
 		}
 		15% {
 			transform: translatex(-10px) rotate(-5deg)
 		}
 		30% {
 			transform: translateX(10px) rotate(5deg)
 		}
 		45% {
 			transform: translatex(0) rotate(0)
 		}
 	}

 	@keyframes flashPokeball {
 		0% {
 			background-color: #fff
 		}
 		to {
 			background-color: #fd0
 		}
 	}

 	.loader-bouncing:after,
 	.loader-bouncing:before {
 		content: "";
 		width: 20px;
 		height: 20px;
 		position: absolute;
 		top: calc(50% - 10px);
 		left: calc(50% - 10px);
 		border-radius: 50%;
 		background-color: #fff;
 		animation: kick .6s infinite alternate
 	}

 	.loader-bouncing:after {
 		margin-left: -30px;
 		animation: kick .6s infinite alternate
 	}

 	.loader-bouncing:before {
 		animation-delay: .2s
 	}

 	@keyframes kick {
 		0% {
 			opacity: 1;
 			transform: translateY(0)
 		}
 		to {
 			opacity: .3;
 			transform: translateY(-1rem)
 		}
 	}
 	