/* <!-- CSS Global Compulsory --> */
@import url(assets/vendor/bootstrap/bootstrap.min.css);
@import url(assets/vendor/icon-line/css/simple-line-icons.css);


/*<!-- CSS Implementing Plugins -->*/
@import url(assets/vendor/icon-awesome/css/font-awesome.min.css);
@import url(assets/vendor/icon-line-pro/style.css);
@import url(assets/vendor/slick-carousel/slick/slick.css);
@import url(assets/vendor/icon-hs/style.css);
@import url(assets/vendor/animate.css);
@import url(assets/vendor/hamburgers/hamburgers.min.css);
@import url(assets/vendor/hs-megamenu/src/hs.megamenu.css);
@import url(assets/vendor/malihu-scrollbar/jquery.mCustomScrollbar.min.css);

@import url(assets/css/styles.e-commerce.css);
@import url(assets/css/custom.css?v=12346);
@import url(assets/css/custom_loading.css);
@import url(assets/vendor/jquery-ui/themes/base/jquery-ui.min.css);


@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap');


body,
div,
p {
    font-family: 'Prompt', sans-serif !important;
}

table,
tr,
td,
th {
    font-family: 'Prompt', sans-serif !important;
}

h1,
h2,
h3 {
    font-family: 'Prompt', sans-serif !important;
}

ul li {
    font-family: 'Prompt', sans-serif !important;
}

a {
    font-family: 'Prompt', sans-serif !important;
}

input,
select,
label,
span {
    font-family: 'Prompt', sans-serif !important;
}
.btn-onesmile {
    background: linear-gradient(108.73deg, #F9881F 23.73%, #F9881F 23.73%, #FF774C 79.34%);
    border-radius: 20px;
    color: white;
}




/*  
 
.basic.address-input-container {
    width: 800px;
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} */

/* .basic .address-input-field-container {
    position: relative;
} */

/* .basic .address-input-field {
    width: 450px;
    margin: 2px 4px;
    padding: 4px 8px;
    border: 2px solid darkgray;
    border-radius: 4px;
    font-size: 18px;
} */


/* .basic  */
.suggestion-container {
    position: absolute;
    z-index: 2;
    background-color: lightgray;
    max-height: 150px;
    overflow: auto;
    list-style-type: none;
    border-radius: 4px;
    bottom: 0px;
    padding: 0;
    margin: 0;
    transform: translateY(100%);
}

/* .basic  */
.suggestion-option {
    padding: 4px 8px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    cursor: pointer;
}

/* .basic  */
.suggestion-option.highlighted {
    background-color: cadetblue;
}