.card-member {
    position: relative;
    display: flex;
    width: 400px;
    /* height: px; */
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background: rgb(239, 69, 77);
    background: linear-gradient(24deg, rgba(239, 69, 77, 1) 0%, rgba(205, 61, 36, 1) 10%, rgba(253, 187, 45, 1) 56%);
    /* background-color: #f15323; */
    background-clip: border-box;
    /* border: 1px solid #f15323; */
    border-radius: 7px;
}

.light {
    color: #7d7979;
    font-size: 11px;
}

.g-bg-black-graient {
    background: linear-gradient(180deg, hsla(0, 0%, 4%, 0), #272727);
    width: 100%;
}

.text-err{
    color: red;
}